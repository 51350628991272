<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 用户管理 -->
  <section class="page-child-contianer">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="姓名">
          <el-input v-model="searchForm.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchForm.mobile" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-select v-model="searchForm.company_id" placeholder="单位">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="(item._child && true) || false"
            >
              <p>
                <template v-if="item.hierarchy > 0">
                  <span
                    class="hierarchy iconfont"
                    v-for="num in item.hierarchy"
                    :key="num"
                  >
                    &#xe603;
                  </span>
                </template>
                {{ item.name }}
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门">
          <el-select v-model="searchForm.branch_id" placeholder="部门">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in branchListSearch"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="(item._child && true) || false"
            >
              <p>
                <template v-if="item.hierarchy > 0">
                  <span
                    class="hierarchy iconfont"
                    v-for="num in item.hierarchy"
                    :key="num"
                  >
                    &#xe603;
                  </span>
                </template>
                {{ item.name }}
              </p>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.status" placeholder="状态">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in staffStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="searchForm.front_role_id" placeholder="角色">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in frontRoleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
        <el-form-item v-if="smallRoleObj.down">
          <el-button
            type="warning"
            @click="handleTemplateDown"
            icon="el-icon-download"
          >
            模板下载
          </el-button>
        </el-form-item>
        <el-form-item v-if="smallRoleObj.import">
          <el-upload
            class="upload-demo"
            action="/api/index/staffImport"
            name="file"
            :headers="{
              token: uploadToken,
            }"
            accept=".xls,.xlsx"
            :show-file-list="false"
            :on-error="uploadError"
            :on-success="uploadSuccess"
            :before-upload="uploadBefore"
            :disabled="importLoading"
          >
            <el-button size="normall" type="success"  icon="el-icon-upload2" :loading="importLoading">
              导入
            </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              v-if="smallRoleObj.add"
              @click="$router.push({ name: 'STAFF_ADD' })"
            >
              添加
            </el-button>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              v-if="smallRoleObj.delete"
              @click="handleDelete()"
            >
              删除
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            />
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="name"
              label="姓名"
              width="180"
              align="center"
            />
            <el-table-column
              prop="mobile"
              label="手机号"
              width="180"
              align="center"
            />

            <el-table-column
              prop="company_name"
              label="所属单位"
              width="180"
              align="center"
            />

            <el-table-column prop="branch_name" label="部门" align="center" />
            <el-table-column
              prop="front_role_name"
              label="角色"
              align="center"
            />
            <el-table-column
              prop="position_rank_name"
              label="职级"
              align="center"
            />
            <el-table-column
              prop="do_member_name"
              label="操作人员"
              align="center"
            />
            <el-table-column prop="status_name" label="状态" align="center" />

            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.create_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="240"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  v-if="smallRoleObj.update"
                  @click="
                    $router.push({
                      name: 'STAFF_EDIT',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  :class="{
                    'color-danger': scope.row.status == 2,
                    'color-success': scope.row.status == 4,
                  }"
                  type="text"
                  icon="el-icon-key"
                  v-if="smallRoleObj.disable"
                  @click="handleStatusUpdate(scope.row.id, scope.row.status)"
                >
                  {{ (scope.row.status == 2 && "禁用") || "启用" }}
                </el-button>

                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-phone"
                  v-if="smallRoleObj.mobile_update"
                  @click="
                    dialogFormVisible = true;
                    mobileUpdateId = scope.row.id;
                  "
                >
                  手机号变更
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>

      <!-- 手机号变更弹窗start -->
      <el-dialog title="手机号变更" :visible.sync="dialogFormVisible">
        <el-form :model="mobileUpdateForm">
          <el-form-item label="新手机号" :label-width="formLabelWidth">
            <el-input
              v-model="mobileUpdateForm.mobile"
              autocomplete="off"
              placeholder="请输入新手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员验证" :label-width="formLabelWidth">
            <el-input
              v-model="mobileUpdateForm.password"
              autocomplete="off"
              type="password"
              placeholder="请输入当前账号的密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle()">取 消</el-button>
          <el-button type="primary" @click="handleMobileUpdate()">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 手机号变更弹窗end -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  getStaffList,
  staffTemplateDown,
  staffDelete,
  staffUpdate,
  staffMobileUpdate,
} from "@api/user"; // 引用./src/api/user.js中的'getStaffList'方法
import { getCompanyListTree, getCompanyList } from "@api/company";
import { getFrontRoleList } from "@api/frontRole";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "staffList",
  inject: ["reload"],
  data() {
    return {
      tableLoading: false,
      importLoading: false,
      tableHeight: 0, //表格最大高度
      uploadToken: "",
      searchForm: {
        branch_id: "",
      }, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      multipleSelection: [], //表格多选储存数据
      staffStatus: [
        {
          value: "2",
          name: "正常",
        },
        {
          value: "4",
          name: "禁用",
        },
      ],
      companyList: [], //单位列表
      frontRoleList: [], //角色列表

      mobileUpdateId: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      mobileUpdateForm: {
        //手机号变更弹窗表单
        id: "",
        mobile: "",
        password: "",
      },
      formLabelWidth: "120px",
      smallRoleObj: {},
      pagination: {
        page: 1,
        total: 0,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch"]),
  },
  async created() {
    // 获取初始化数据
    let res = await getCompanyList();
    this.companyList = res.data;
    let res2 = await getFrontRoleList();
    this.frontRoleList = res2.data;

    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    this.uploadToken = sessionStorage.getItem("token");
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    this.$forceUpdate();
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      this.tableLoading = true;
      /**
       * 请求接口数据
       */
      let res = await getStaffList(tempParams);
      this.tableData = res.data.list;

      this.pagination.total = res.data.count;
      this.$forceUpdate();
      this.tableLoading = false;
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancle() {
      this.dialogFormVisible = false;
      this.mobileUpdateId = 0;
      this.mobileUpdateForm = {};
    },
    async handleTemplateDown() {
      await staffTemplateDown();
    },
    /** 删除用户 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(async () => {
            /**
             * 请求接口删除数据
             */
            let res = await staffDelete(id);
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            /** 判断当前页数据是否仅剩1条 */
            if (this.tableData.length == 1) {
              this.pagination = {
                ...this.pagination,
                page:
                  (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
              };
            }
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
            }
            let tempParams = {
              ...this.searchForm,
              page: this.pagination.page,
              rows: this.pagination.size,
            };
            this.getData(tempParams);
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
    /**
     * 状态变更
     */
    async handleStatusUpdate(id, status) {
      let params = {
        id: id,
      };
      if (status) {
        params.status = (status == 2 && 4) || 2;
      }
      let res = await staffUpdate(params, "post");
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.getData();
    },
    /**
     * 手机号变更
     */
    async handleMobileUpdate() {
      let params = {
        id: this.mobileUpdateId,
        mobile: this.mobileUpdateForm.mobile,
        password: this.mobileUpdateForm.password,
      };
      let res = await staffMobileUpdate(params, "post");
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }

      this.handleCancle();
      this.getData();
    },

    uploadBefore() {
      this.importLoading = true;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getData();
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
      this.importLoading = false;
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning(err);
      this.importLoading = false;
    },
  },
};
</script>
